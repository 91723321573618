// link required packages
import $ from 'jquery';
window.jQuery = $; window.$ = $;

import '../javascripts/matchHeight.js';
import '../javascripts/jquery.lightbox_me.js';
import 'animsition'
import 'cookieconsent'

window.addEventListener("load", function () {
  window.cookieconsent.initialise({
    "palette": {
      "popup": {
        "background": "#252e39"
      },
      "button": {
        "background": "#fff"
      }
    },
    "theme": "classic",
    "content": {
      "message": "Deze website maakt gebruik van cookies om ervoor te zorgen dat u de beste ervaring op onze website krijgt.",
      "dismiss": "Accepteer cookies",
      "link": "Bekijk ons beleid",
      "href": "/site/static/files/algemene-voorwaarden.pdf"
    }
  })
});

// link app javascripts
import '../javascripts/application.js';

// link stylesheets
import '../stylesheets/application.scss';
